<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mill </span>
                                <v-autocomplete
                                    :disabled="isMillDisabled"
                                    outlined
                                    dense
                                    v-model="mill"
                                    :items="mills"
                                    default="" item-value="mill_id" item-text="mill_name"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mach Type </span>
                                <v-autocomplete 
                                    dense 
                                    outlined
                                    class="ma-0 pa-0 mt-2 border-12" 
                                    hide-details=true v-model="machType"
                                    :items="machTypes" default="" item-value="mach_type" item-text="mach_type"
                                    :disabled="isMachTypeDisabled" :auto-select-first="isMachTypeDisabled" @change="(event) => getMachId(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mach Id </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="machId"
                                    :items="machIds" default="" item-value="mach_id" item-text="mach_id"
                                    :disabled="isMachIdDisabled" :auto-select-first="isMachIdDisabled" @change="(event) => getMaintSection(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Section </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="section"
                                    :items="sections" default="" item-value="section_id" :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                    :disabled="isSectionDisabled" :auto-select-first="isSectionDisabled" clearable>
                                </v-autocomplete>
                            </v-col>
                            <!-- Belum var -->
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Wo Status </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="woStatus"
                                    :items="woStatuses" default="" item-value="id" :item-text="item => item.id +' - '+ item.descr"
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Priority </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="priority"
                                    :items="priorities" default="" item-value="id" :item-text="item => item.id +' - '+ item.descr"
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Date of </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="dateOf"
                                    :items="dateOfes" default="" item-value="id" :item-text="item => item.descr"
                                    auto-select-first
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Date </span>
                                <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                    offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                            persistent-hint append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        no-title @input="startDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">End Date </span>
                                <v-menu ref="endDateModal"
                                v-model="endDateModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="endDate"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        no-title @input="endDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Role </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="role"
                                    :items="roles" default="" item-value="id" :item-text="item => item.descr"
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Nik </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 border-12" hide-details=true v-model="nik"
                                    :items="niks" default="" item-value="idemployee" :item-text="item => item.idemployee +' - '+ item.name"
                                    clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-4 border-12" style="padding: 19px;" color="info" elevation="2" small @click="getHeader('search', [])"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-dialog v-model="dialogHeaderModal" class="rounded-xl" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{dialogTitle}} <span class="text-darken-3 m-0"> {{ woNum }}</span> </v-toolbar-title>
                </v-toolbar>
                <v-card outlined class="mb-4 mt-4 ml-2 mr-2" >
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Type </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="maintenanceType"
                                    :items="maintenanceTypes" default="" item-value="id" item-text="descr"
                                    disabled>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Wo Status </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="woStatusDialog"
                                    :items="woStatuses" default="" item-value="id" :item-text="item => item.id +' - '+ item.descr"
                                    clearable :disabled="isWoStatusDisabled" auto-select-first>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Priority <strong style="color:red;">*</strong></span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="priorityDialog"
                                    :items="priorities" default="" item-value="id" :item-text="item => item.id +' - '+ item.descr"
                                    clearable :disabled="isView">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mill <strong style="color:red;">*</strong></span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="millDialog"
                                    :items="millsDialog" default="" item-value="mill_id" item-text="mill_name"
                                    :disabled="isMillDialogDisabled" :auto-select-first="isMillDialogDisabled"
                                    @change="(event) => getMachTypeDialog(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mach Type <strong style="color:red;">*</strong></span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="machTypeDialog"
                                    :items="machTypesDialog" default="" item-value="mach_type" item-text="mach_type"
                                    :disabled="isMachTypeDialogDisabled" :auto-select-first="isMachTypeDialogDisabled"
                                    @change="(event) => getMachIdDialog(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mach Id <strong style="color:red;">*</strong></span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="machIdDialog"
                                    :items="machIdsDialog" default="" item-value="mach_id" item-text="mach_id"
                                    :disabled="isMachIdDialogDisabled" :auto-select-first="isMachIdDialogDisabled"
                                    @change="(event) => getMaintSectionDialog(event)">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Section <strong style="color:red;">*</strong></span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2" hide-details=true v-model="sectionDialog"
                                    :items="sectionsDialog" default="" item-value="section_id" :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                    :disabled="isSectionDialogDisabled" :auto-select-first="isSectionDialogDisabled">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Total Mechanic </span>
                                <v-text-field dense outlined class="ma-0 pa-0 mt-2" v-model="totalMechDialog"
                                    single-line hide-details
                                    :disabled="isView">
                                </v-text-field>
                            </v-col>
                            
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Date </span>
                                <v-datetime-picker 
                                    v-model="startDateDialog"
                                    no-title:true 
                                    
                                    :date-picker-props="{
                                        headerColor:'blue',
                                    }"
                                    :time-picker-props="{
                                        scrollable: true,
                                        format:'24hr'
                                    }"
                                    time-format="HH:mm:ss"
                                    :disabled="isView">
                                </v-datetime-picker>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">End Date </span>
                                <v-datetime-picker 
                                    v-model="endDateDialog"
                                    no-title:true 
                                    
                                    :date-picker-props="{
                                        headerColor:'blue',
                                    }"
                                    :time-picker-props="{
                                        scrollable: true,
                                        format:'24hr'
                                    }"
                                    time-format="HH:mm:ss"
                                    :disabled="isView">
                                </v-datetime-picker>
                            </v-col>
                            <!-- <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd ma-0 pa-0">End Date </p>
                                <v-menu ref="endDateDialogModal"
                                v-model="endDateDialogModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo
                                        v-model="endDateDialog"
                                        label="End Date"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDateDialog"
                                        no-title @input="endDateDialogModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col> -->
                            <v-col class="col-6" sm="6" md="4">
                                <span class="blue-lcd mb-2 font-12">Remark </span>
                                <v-textarea class="ma-0 pa-0 mt-2" dense outlined v-model="remarkDialog" auto-grow rows="1" :disabled="isView"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Accepted by </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 " hide-details=true v-model="acceptedBy"
                                    :items="niks" default="" item-value="idemployee" :item-text="item => item.idemployee +' - '+ item.name"
                                    clearable :disabled="isView">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">QC by </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 " hide-details=true v-model="qcBy"
                                    :items="niks" default="" item-value="idemployee" :item-text="item => item.idemployee +' - '+ item.name"
                                    clearable :disabled="isView">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Checked by </span>
                                <v-autocomplete dense outlined class="ma-0 pa-0 mt-2 " hide-details=true v-model="checkedBy"
                                    :items="niks" default="" item-value="idemployee" :item-text="item => item.idemployee +' - '+ item.name"
                                    clearable :disabled="isView">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-3" sm="6" md="1">
                                <v-btn v-if="isNew === true && isUpdate === false && isView === false" class="mt-4 mr-2 border-12"
                                    color="primary" style="padding: 10px;" block elevation="2" @click="save('save', 0, [])">Save Draft PMT</v-btn>
                                <v-btn v-if="isNew === false && isUpdate === true && isView === false" class="mt-4 mr-4 border-12"
                                    color="primary" style="padding: 10px;" block elevation="2" @click="update()">Update PMT</v-btn>
                            </v-col>
                            <v-col class="col-3" sm="6" md="1" v-if="isNew === false && isUpdate === true && isView === false">
                                <v-btn v-if="isNew === false && isUpdate === true && isView === false" class="mt-4 mr-4 border-12"
                                    color="error" style="padding: 10px;" block elevation="2" @click="save('save', 255, [])">Cancel PMT</v-btn>
                            </v-col>
                            <v-col class="col-3" sm="6" md="1">
                                <v-btn class="mt-4 mr-4  border-12" color="primary" style="padding: 10px;" block elevation="2"
                                    @click="closeDialog()">Close</v-btn>
                            </v-col>
                        </v-row>
                        <!-- <v-row align="center" justify="center" class="mt-0">
                            <v-col class="col-3" sm="6" md="1">
                                <v-btn v-if="isNew === true && isUpdate === false && isView === false" class="mt-0 mr-2 border-12"
                                    color="primary" style="padding: 10px;" block small elevation="2" @click="save('save', 0, [])">Save Draft PMT</v-btn>
                                <v-btn v-if="isNew === false && isUpdate === true && isView === false" class="mt-0 mr-4 border-12"
                                    color="primary" style="padding: 10px;" block small elevation="2" @click="update()">Update PMT</v-btn>
                            </v-col>
                            <v-col v-if="isView === false" class="col-3" sm="6" md="1"> -->
                                <!-- <v-btn v-if="isView === false && this.levelFlags.isLevel1 === true && woStatusDialog == 0" class="mt-0 mr-4 border-12"
                                    color="success" style="padding: 10px;" block small elevation="2" @click="save('submit', 1, [])">Submit</v-btn> -->
                                <!-- <v-btn v-if="isView === false && this.levelFlags.isLevel2 === true && woStatusDialog == 1" class="mt-0 mr-4 border-12"
                                    color="primary" style="padding: 10px;" block small elevation="2" @click="save('approve', 2, [])">Approve</v-btn> -->
                                <!-- <v-btn v-if="isView === false && this.levelFlags.isLevel3 === true && (woStatusDialog == 2 || woStatusDialog == 5 || woStatusDialog == 255)" class="mt-0 mr-4 border-12"
                                    color="primary" style="padding: 10px;" block small elevation="2" @click="save('accept', 3, [])">Finalize</v-btn> -->
                                <!-- <v-btn v-if="isView === false && this.levelFlags.isLevel4 === true && woStatusDialog == 3" class="mt-0 mr-4 border-12"
                                    color="primary" style="padding: 10px;" block small elevation="2" @click="save('qc', 4, [])">QC</v-btn>
                                <v-btn v-if="isView === false && this.levelFlags.isLevel5 === true && woStatusDialog == 4" class="mt-0 mr-4 border-12"
                                    color="primary" style="padding: 10px;" block small elevation="2" @click="save('check', 5, [])">Check</v-btn> -->
                            <!-- </v-col>
                            <v-col class="col-3" sm="6" md="1">
                                <v-btn v-if="isNew === false && isUpdate === true && isView === false" class="mt-0 mr-4 border-12"
                                    color="error" style="padding: 10px;" block small elevation="2" @click="save('save', 255, [])">Cancel PMT</v-btn>
                            </v-col>
                            <v-col class="col-3" sm="6" md="1">
                                <v-btn class="mt-0 mr-4  border-12" color="primary" style="padding: 10px;" block small elevation="2"
                                    @click="closeDialog()">Close</v-btn>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-tabs v-model="selectedTab" class="round_top col-sm-no-padding ml-5 pt-5"
                                icons-and-text show-arrows>
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab v-for="(tab, index) in userTabs" :key="index" :href="'#tab-'+tab.int1" active-class="active-tab">
                                    {{ tab.name }}
                                </v-tab>
                            </v-tabs>
                            <!-- <v-tabs-items v-model="selectedTab">
                                <v-tab-item v-for="(tab, index) in userTabs" :key="index">
                                    <component :is="tab.component"></component>
                                </v-tab-item>
                            </v-tabs-items> -->
                            <v-tabs-items v-model="selectedTab">
                                <v-tab-item value="tab-1">
                                    <v-container v-if="isView === false" fluid class="mb-0 mt-0 pt-2">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12" class="mt-0">
                                                                <v-data-table
                                                                    :headers="headerOutsCheckList"
                                                                    :items="dataCheckList"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemChecklist"
                                                                    disable-pagination
                                                                    hide-default-footer
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Outstanding</p>
                                                                                <v-spacer></v-spacer>
                                                                                <v-autocomplete solo style="max-width: 300px;" class="border-12 ma-0 mr-3 pa-0 "
                                                                                    dense
                                                                                    clearable
                                                                                    label="Part List"
                                                                                    v-model="part_mach_outs"
                                                                                    :items="part_machs_outs"
                                                                                    item-value="part_id"
                                                                                    :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                                                                    hide-details=true
                                                                                ></v-autocomplete>
                                                                                
                                                                                <v-menu
                                                                                    ref="modal_start_date_outs"
                                                                                    v-model="modal_start_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="startDateOuts"
                                                                                            label="Start Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="startDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_start_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu>
                                                                                <v-menu
                                                                                    ref="modal_finish_date_outs"
                                                                                    v-model="modal_finish_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="finishDateOuts"
                                                                                            label="Finish Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="finishDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_finish_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu>
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                                <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" :disabled="isAddReasonDisabled" @click="getOutstandingCheckList()"> Search </v-btn>
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="addReason('insert', item)" v-on="on">
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Add Reason</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    
                                                                    <!-- <template v-slot:[`item.active_flag`]="{ item }">
                                                                        <v-switch
                                                                            v-model="item.active_flag"
                                                                            inset
                                                                            color="primary"
                                                                            class="pt-1"
                                                                            true-value="1"
                                                                            false-value="0"
                                                                            dense
                                                                            @change="changeActiveFlag(item)"
                                                                        ></v-switch>
                                                                    </template> -->
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-container fluid class="mb-0">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-data-table
                                                                    :headers="headerReason"
                                                                    :items="dataReason"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemReason"
                                                                    disable-pagination
                                                                    hide-default-footer
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Reason</p>
                                                                                <v-spacer></v-spacer>
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                                <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" :disabled="isAddReasonDisabled" @click="getReason()"> Refresh </v-btn>
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="deleteReason('delete', item)" v-on="on" :disabled=isView>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Delete Reason</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    <template v-slot:[`item.trans_date`]="{ item }">
                                                                        {{ new Date((new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() *
                                                                                60000)).toISOString()
                                                                        ).toLocaleString('en-US', {
                                                                            month: 'short',
                                                                            day: '2-digit',
                                                                            year: 'numeric'
                                                                        }) }}
                                                                    </template>
                                                                    <!-- <template v-slot:[`item.active_flag`]="{ item }">
                                                                        <v-switch
                                                                            v-model="item.active_flag"
                                                                            inset
                                                                            color="primary"
                                                                            class="pt-1"
                                                                            true-value="1"
                                                                            false-value="0"
                                                                            dense
                                                                            @change="changeActiveFlag(item)"
                                                                        ></v-switch>
                                                                    </template> -->
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>
                                
                                <v-tab-item value="tab-2">
                                    <v-container v-if="isView === false" fluid class="mb-0 mt-0 pt-2">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-data-table
                                                                    :headers="headerMech"
                                                                    :items="dataMech"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemMech"
                                                                    disable-pagination
                                                                    hide-default-footer
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Mechanic</p>
                                                                                <v-spacer></v-spacer>
                                                                                <!-- <v-autocomplete solo style="max-width: 300px;" class="border-12 ma-0 mr-3 pa-0 "
                                                                                    dense
                                                                                    clearable
                                                                                    label="Part List"
                                                                                    v-model="part_mach_outs"
                                                                                    :items="part_machs_outs"
                                                                                    item-value="part_id"
                                                                                    :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                                                                    hide-details=true
                                                                                ></v-autocomplete>
                                                                                
                                                                                <v-menu
                                                                                    ref="modal_start_date_outs"
                                                                                    v-model="modal_start_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="startDateOuts"
                                                                                            label="Start Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="startDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_start_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu>
                                                                                <v-menu
                                                                                    ref="modal_finish_date_outs"
                                                                                    v-model="modal_finish_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="finishDateOuts"
                                                                                            label="Finish Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="finishDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_finish_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu> -->
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItemMech" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                                <!-- <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" :disabled="isAddReasonDisabled" @click="getOutstandingCheckList()"> Search </v-btn> -->
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="addMechanic('insert', item)" v-on="on">
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Add Mechanic</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    <!-- <template v-slot:[`item.active_flag`]="{ item }">
                                                                        <v-switch
                                                                            v-model="item.active_flag"
                                                                            inset
                                                                            color="primary"
                                                                            class="pt-1"
                                                                            true-value="1"
                                                                            false-value="0"
                                                                            dense
                                                                            @change="changeActiveFlag(item)"
                                                                        ></v-switch>
                                                                    </template> -->
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-container fluid class="mb-0">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-data-table
                                                                    :headers="headerManPower"
                                                                    :items="dataManPower"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemManPower"
                                                                    disable-pagination
                                                                    hide-default-footer
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Man Power</p>
                                                                                <v-spacer></v-spacer>
                                                                                <!-- <v-autocomplete solo style="max-width: 300px;" class="border-12 ma-0 mr-3 pa-0 "
                                                                                    dense
                                                                                    clearable
                                                                                    label="Part List"
                                                                                    v-model="part_mach_outs"
                                                                                    :items="part_machs_outs"
                                                                                    item-value="part_id"
                                                                                    :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                                                                    hide-details=true
                                                                                ></v-autocomplete>
                                                                                
                                                                                <v-menu
                                                                                    ref="modal_start_date_outs"
                                                                                    v-model="modal_start_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="startDateOuts"
                                                                                            label="Start Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="startDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_start_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu>
                                                                                <v-menu
                                                                                    ref="modal_finish_date_outs"
                                                                                    v-model="modal_finish_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="finishDateOuts"
                                                                                            label="Finish Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="finishDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_finish_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu> -->
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItemManPower" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                                <!-- <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" :disabled="isAddReasonDisabled" @click="getOutstandingCheckList()"> Search </v-btn> -->
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="deleteMechanic('delete', item)" v-on="on" :disabled=isView>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Remove Mechanic</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    <!-- <template v-slot:[`item.active_flag`]="{ item }">
                                                                        <v-switch
                                                                            v-model="item.active_flag"
                                                                            inset
                                                                            color="primary"
                                                                            class="pt-1"
                                                                            true-value="1"
                                                                            false-value="0"
                                                                            dense
                                                                            @change="changeActiveFlag(item)"
                                                                        ></v-switch>
                                                                    </template> -->
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>

                                <v-tab-item value="tab-3">
                                    <v-container v-if="isView === false" fluid class="mb-0 mt-0 pt-2">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row align="">
                                                            <v-col class="col-6" sm="6" md="2">
                                                                <p class="blue-lcd ma-0 pa-0 border-12">Start Date </p>
                                                                <v-datetime-picker 
                                                                    v-model="dialogWorkStartDate"
                                                                    no-title:true 
                                                                    
                                                                    :date-picker-props="{
                                                                        headerColor:'blue',
                                                                    }"
                                                                    :time-picker-props="{
                                                                        scrollable: true,
                                                                        format:'24hr'
                                                                    }"
                                                                    time-format="HH:mm:ss">
                                                                </v-datetime-picker>
                                                            </v-col>
                                                            <v-col class="col-6" sm="6" md="2">
                                                                <p class="blue-lcd ma-0 pa-0 border-12">End Date </p>
                                                                <v-datetime-picker 
                                                                    v-model="dialogWorkEndDate"
                                                                    no-title:true 
                                                                    
                                                                    :date-picker-props="{
                                                                        headerColor:'blue',
                                                                    }"
                                                                    :time-picker-props="{
                                                                        scrollable: true,
                                                                        format:'24hr'
                                                                    }"
                                                                    time-format="HH:mm:ss">
                                                                </v-datetime-picker>
                                                            </v-col>
                                                            <v-col class="col-6" sm="6" md="1">
                                                                <v-btn class="border-12 mt-2" style="padding: 19px;" small block color="success" elevation="2" 
                                                                :disabled="timeRecordButtonDisabled" :loading="timeRecordButtonDisabled"
                                                                @click="addTimeRecord()"> {{ timeRecordButton }} </v-btn>
                                                            </v-col>
                                                            <v-col v-if="timeRecordButton == 'Update'" class="col-6" sm="6" md="1">
                                                                <v-btn class="border-12 mt-2" style="padding: 19px;" small block color="info" elevation="2" 
                                                                :disabled="timeRecordButtonDisabled" :loading="timeRecordButtonDisabled"
                                                                @click="clearTimeRecord()"> Reset </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-container fluid class="mb-0">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-data-table
                                                                    :headers="headerTimeRecord"
                                                                    :items="dataTimeRecord"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemTimeRecord"
                                                                    disable-pagination
                                                                    hide-default-footer
                                                                    @click:row="timeRecordTableClick"
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Work Hours</p>
                                                                                <v-spacer></v-spacer>
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItemTimeRecord" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="delTimeRecord('delete', item)" v-on="on" :disabled=isView>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Remove Time Record</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    
                                                                    <template v-slot:[`item.start_date_time`]="{ item }">
                                                                        {{ new Date((new Date(new Date(item.start_date_time) - (new Date()).getTimezoneOffset() *
                                                                                60000)).toISOString()
                                                                        ).toLocaleString('en-US', {
                                                                            month: 'short',
                                                                            day: '2-digit',
                                                                            year: 'numeric',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            hour12: false
                                                                        }) }}
                                                                    </template>
                                                                    <template v-slot:[`item.end_date_time`]="{ item }">
                                                                        {{ new Date((new Date(new Date(item.end_date_time) - (new Date()).getTimezoneOffset() *
                                                                                60000)).toISOString()
                                                                        ).toLocaleString('en-US', {
                                                                            month: 'short',
                                                                            day: '2-digit',
                                                                            year: 'numeric',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            hour12: false
                                                                        }) }}
                                                                    </template>
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>

                                <v-tab-item value="tab-4">
                                    <v-container v-if="isView === false" fluid class="mb-0 mt-0 pt-2">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-data-table
                                                                    :headers="headerInventory"
                                                                    :items="dataInventory"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemInventory"
                                                                    
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Inventory</p>
                                                                                <v-spacer></v-spacer>
                                                                                <!-- <v-autocomplete solo style="max-width: 300px;" class="border-12 ma-0 mr-3 pa-0 "
                                                                                    dense
                                                                                    clearable
                                                                                    label="Part List"
                                                                                    v-model="part_mach_outs"
                                                                                    :items="part_machs_outs"
                                                                                    item-value="part_id"
                                                                                    :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                                                                    hide-details=true
                                                                                ></v-autocomplete>
                                                                                
                                                                                <v-menu
                                                                                    ref="modal_start_date_outs"
                                                                                    v-model="modal_start_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="startDateOuts"
                                                                                            label="Start Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="startDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_start_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu>
                                                                                <v-menu
                                                                                    ref="modal_finish_date_outs"
                                                                                    v-model="modal_finish_date_outs"
                                                                                    :close-on-content-click="false"
                                                                                    transition="scale-transition"
                                                                                    offset-y
                                                                                    max-width="300"
                                                                                    min-width="auto"
                                                                                    >
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-text-field
                                                                                            solo
                                                                                            dense
                                                                                            v-model="finishDateOuts"
                                                                                            label="Finish Date"
                                                                                            style="max-width: 300px;"
                                                                                            persistent-hint
                                                                                            append-icon="mdi-calendar"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            class="ma-0 pa-0 border-12 mr-3"
                                                                                            hide-details=true
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <v-date-picker
                                                                                        v-model="finishDateOuts"
                                                                                        no-title
                                                                                        @input="[modal_finish_date_outs = false]"
                                                                                    ></v-date-picker>
                                                                                </v-menu> -->
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItemInventory" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                                <!-- <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" :disabled="isAddReasonDisabled" @click="getOutstandingCheckList()"> Search </v-btn> -->
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="addReqSparepart('insert', item)" v-on="on">
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Add Sparepart Request</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    <template v-slot:[`item.req_qty`]="{ item }">
                                                                        <v-text-field
                                                                            dense
                                                                            outlined
                                                                            type="number"
                                                                            v-model="item.req_qty"
                                                                            style="max-width: 100px;"
                                                                            persistent-hint
                                                                            class="mt-2 mb-0 pa-0 mr-3"
                                                                            hide-details=true
                                                                        ></v-text-field>
                                                                    </template>
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-container fluid class="mb-0">
                                        <v-row class="mb-0">
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-data-table
                                                                    :headers="headerReqSparepart"
                                                                    :items="dataReqSparepart"
                                                                    :loading="$store.state.overlay"
                                                                    item-class="tr_datatable"
                                                                    fixed-header
                                                                    height="250"
                                                                    :divider="true"
                                                                    :light="true"
                                                                    :search="searchItemReqSparepart"
                                                                    disable-pagination
                                                                    hide-default-footer
                                                                >
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat color="white">
                                                                            <div class="d-flex w-100">
                                                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">Sparepart Request</p>
                                                                                <v-spacer></v-spacer>
                                                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItemReqSparepart" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                                            </div>
                                                                        </v-toolbar>
                                                                    </template>
                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <td>
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon small class="mr-2" @click="deleteReqSparepart('delete', item)" v-on="on" :disabled=isView>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </template>
                                                                                <span>Remove Mechanic</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </template>
                                                                    <!-- <template v-slot:[`item.active_flag`]="{ item }">
                                                                        <v-switch
                                                                            v-model="item.active_flag"
                                                                            inset
                                                                            color="primary"
                                                                            class="pt-1"
                                                                            true-value="1"
                                                                            false-value="0"
                                                                            dense
                                                                            @change="changeActiveFlag(item)"
                                                                        ></v-switch>
                                                                    </template> -->
                                                                </v-data-table>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-row>
                        <v-row class="mt-0 justify-end">
                            <v-col v-if="isView === false" class="col-3" sm="6" md="1">
                                <v-btn v-if="isView === false && this.levelFlags.isLevel1 === true && woStatusDialog == 0" class="mt-0 mr-4 border-12"
                                    color="success" style="padding: 10px;" block small elevation="2" @click="save('submit', 1, [])">Submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="500"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile" style="font-size: large;">List Maintenance</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="searchItem" append-icon="mdi-magnify" dense  hide-details></v-text-field>
                                                <v-btn class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="openDialog('new', [])"><v-icon>mdi-plus</v-icon> New PMT </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="getHeader('update', item)" v-on="on">
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <span>Edit Config</span>
                                            </v-tooltip>
                                        </td>
                                    </template>
                                    <!-- <template v-slot:[`item.active_flag`]="{ item }">
                                        <v-switch
                                            v-model="item.active_flag"
                                            inset
                                            color="primary"
                                            class="pt-1"
                                            true-value="1"
                                            false-value="0"
                                            dense
                                            @change="changeActiveFlag(item)"
                                        ></v-switch>
                                    </template> -->
                                    <template v-slot:[`item.start_date_time`]="{ item }">
                                        {{ new Date((new Date(new Date(item.start_date_time) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                            
                                    </template>
                                    <template v-slot:[`item.end_date_time`]="{ item }">
                                        {{ new Date((new Date(new Date(item.end_date_time) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                    </template>
                                    <template v-slot:[`item.submit_date`]="{ item }">
                                        {{ new Date((new Date(new Date(item.submit_date) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                    </template>
                                    <template v-slot:[`item.approved_date`]="{ item }">
                                        {{ new Date((new Date(new Date(item.approved_date) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                    </template>
                                    <template v-slot:[`item.accepted_date`]="{ item }">
                                        {{ new Date((new Date(new Date(item.accepted_date) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                    </template>

                                    <template v-slot:[`item.qc_date`]="{ item }">
                                        {{ new Date((new Date(new Date(item.qc_date) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                    </template>
                                    <template v-slot:[`item.checked_date`]="{ item }">
                                        {{ new Date((new Date(new Date(item.checked_date) - (new Date()).getTimezoneOffset() *
                                                60000)).toISOString()
                                        ).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        }) }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { woAprvConfig } from "../../../backend-api/viva/maintenance/woAprvConfig";
import { woHeader } from "../../../backend-api/viva/maintenance/woHeader";

export default{
    props: {
        companyId: String,
        maintenanceType: String,
    },
    data(){
        return{
            tzoffsetConvert : new Date().getTimezoneOffset() * 60000,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Maintenance',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Planned Maintenance',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            // userConfig
            userConfig:[],
            levelFlags: {},
            // Filter
            mill:'',
            mills:[],
            isMillDisabled:false,
            machType:'',
            machTypes:[],
            isMachTypeDisabled:false,
            machId:'',
            machIds:[],
            isMachIdDisabled:false,
            section:'',
            sections:[],
            isSectionDisabled:false,
            woStatus:'',
            woStatuses:[
                {id: '0', descr: 'Dibuat'},
                {id: '1', descr: 'Diajukan'},
                {id: '2', descr: 'Disetujui'},
                // {id: '3', descr: 'Diterima'},
                // {id: '4', descr: 'QC'},
                {id: '5', descr: 'Diperiksa'},
                {id: '255', descr: 'Dibatalkan'},],
            priority:'',
            priorities:[{id: 'L', descr: 'Low'},
                {id: 'H', descr: 'High'},],
            dateOf:'start',
            dateOfes:[{id: 'start', descr: 'Start WO'},
                {id: 'finish', descr: 'End WO'},
                {id: 'submit', descr: 'Submit'},
                {id: 'approved', descr: 'Approve'},
                {id: 'accepted', descr: 'Accept'},
                {id: 'qc', descr: 'QC'},
                {id: 'checked', descr: 'Check'}],
            startDateModal:false,
            startDate:'',
            endDateModal:false,
            endDate:'',
            role:'',
            roles:[
                {id: 'submit', descr: 'Submit'},
                {id: 'approved', descr: 'Approve'},
                {id: 'accepted', descr: 'Accept'},
                {id: 'qc', descr: 'QC'},
                {id: 'checked', descr: 'Check'}],
            nik:'',
            niks:[],
            // Table
            searchItem:'',
            header:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Mill', value: 'mill_id'},
                { text: 'Maint Num', value: 'wo_num'},
                { text: 'Status', value: 'wo_status'},
                { text: 'Mach Type', value: 'mach_type'},
                { text: 'Mach Id', value: 'mach_id'},
                { text: 'Section', value: 'maint_section_descr'},
                { text: 'Priority', value: 'priority'},
                { text: 'Total Mechanic', value: 'total_mechanic'},
                { text: 'Start Date', value: 'start_date_time'},
                { text: 'Close Date', value: 'end_date_time'},
                { text: 'Submit', value: 'submit_by'},
                { text: 'Date submit', value: 'submit_date'},
                { text: 'Approved By', value: 'approved_by'},
                { text: 'Date Approved', value: 'approved_date'},
                { text: 'Accepted By', value: 'accepted_by'},
                { text: 'Date Accepted', value: 'accepted_date'},
                { text: 'Qc by', value: 'qc_by'},
                { text: 'Date Qc', value: 'qc_date'},
                { text: 'Checked By', value: 'checked_by'},
                { text: 'Date Checked', value: 'checked_date'},
            ],
            headersColumn:{
                'Mill':'mill_id',
                'Maint Num':'wo_num',
                'Status':'wo_status',
                'Mach Type':'mach_type',
                'Mach Id':'mach_id',
                'Section':'maint_section_descr',
                'Priority':'priority',
                'Total Mechanic':'total_mechanic',
                'Start Date':'start_date_time',
                'Close Date':'end_date_time',
                'Submit':'submit_by',
                'Date submit':'submit_date',
                'Approved By':'approved_by',
                'Date Approved':'approved_date',
                'Accepted By':'accepted_by',
                'Date Accepted':'accepted_date',
                'Qc by':'qc_by',
                'Date Qc':'qc_date',
                'Checked By':'checked_by',
                'Date Checked':'checked_date'
            },
            data:[],

            dialogHeaderModal:false,
            dialogTitle:'',
            isNew:false,
            isUpdate:false,
            isView:false,
            woNum:'',
            maintenanceTypes:[
                {id: 'P', descr: 'Planned'},
                {id: 'U', descr: 'Unplanned'}],
            isWoStatusDisabled:true,
            millDialog:'',
            millsDialog:[],
            isMillDialogDisabled:false,
            machTypeDialog:'',
            machTypesDialog:[],
            isMachTypeDialogDisabled:false,
            machIdDialog:'',
            machIdsDialog:[],
            isMachIdDialogDisabled:false,
            sectionDialog:'',
            sectionsDialog:[],
            isSectionDialogDisabled:false,
            woStatusDialog:'',
            priorityDialog:'',
            totalMechDialog:'',
            startDateDialogModal:false,
            startDateDialog:'',
            endDateDialogModal:false,
            endDateDialog:'',
            remarkDialog:'',
            acceptedBy:'',
            qcBy:'',
            checkedBy:'',

            isSubmit:true,

            selectedTab:0,
            userTabs: [
                {int1: 1, name: 'Reason'},//component: woReasonComponent},
                {int1: 2, name: 'Mechanic'},// component: woMechanicComponent},
                {int1: 3, name: 'Time Record'}, //component: woTimeRecord},
                {int1: 4, name: 'Sparepart Request'}, //component: woSpareparReq},
            ],
            // Reason
            headerOutsCheckList:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'TR Id', value: 'tr_id'},
                { text: 'Trans Date', value: 'trans_date'},
                { text: 'Check Desc', value: 'check_descr'},
                { text: 'Period', value: 'period'},
                { text: 'Shift', value: 'shift'},
                { text: 'Min', value: 'min'},
                { text: 'Max', value: 'max'},
                { text: 'Result', value: 'check_result'}
            ],
            dataCheckList:[],
            searchItemChecklist:'',
            headerReason:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'TR Id', value: 'tr_id'},
                { text: 'Trans Date', value: 'trans_date'},
                { text: 'Part Desc', value: 'part_desc'},
                { text: 'Check Desc', value: 'check_descr'},
                { text: 'Remark', value: 'remark'},
                { text: 'Min', value: 'min'},
                { text: 'Max', value: 'max'},
                { text: 'Result', value: 'check_result'}
            ],
            isAddReasonDisabled:true,
            timeRecorSeqNo:0,
            dataReason:[],
            searchItemReason:'',
            part_mach_outs:'',
            part_machs_outs:[],
            modal_start_date_outs:false,
            startDateOuts:'',
            modal_finish_date_outs:false,
            finishDateOuts:'',
            // Mechanic
            headerMech:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Nik', value: 'idemployee'},
                { text: 'Name', value: 'name'},
                { text: 'Dept', value: 'dept_id'},
                { text: 'Division', value: 'division_name'}
            ],
            dataMech:[],
            searchItemMech:'',
            headerManPower:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Nik', value: 'mechanic'},
                // { text: 'Name', value: 'name'},
                // { text: 'Dept', value: 'dept_id'},
                // { text: 'Division', value: 'division_name'}
            ],
            dataManPower:[],
            searchItemManPower:'',
            timeRecordButton:'Add',
            timeRecordButtonDisabled:false,
            headerTimeRecord:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Code', value: 'code_id'},
                { text: 'Start', value: 'start_date_time'},
                { text: 'End', value: 'end_date_time'},
                { text: 'Duration', value: 'duration'}
            ],
            dataTimeRecord:[],
            searchItemTimeRecord:'',
            // dialogCode:'',
            // dialogCodes:[{id: 'L', descr: 'Low'},
            //     {id: 'H', descr: 'High'},],
            dialogWorkStartDate:'',
            dialogWorkEndDate:'',

            headerInventory : [
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Req Qty', value: 'req_qty'},
                { text: 'Article', value: 'article_id'},
                { text: 'Desc', value: 'description'},
                { text: 'Commodity', value: 'com_id'},
                { text: 'WH', value: 'wh_id'},
                { text: 'Unit', value: 'uom'},
                { text: 'On Hand', value: 'qty_oh'}
            ],
            dataInventory : [],
            searchItemInventory : '',
            headerReqSparepart : [
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Article', value: 'prod_code'},
                // { text: 'Desc', value: 'description'},
                { text: 'Commodity', value: 'com_id'},
                // { text: 'WH', value: 'wh_id'},
                // { text: 'Unit', value: 'uom'},
                { text: 'Req Qty', value: 'req_qty'}
                // { text: 'On Hand', value: 'qty_oh'}
            ],
            dataReqSparepart : [],
            searchItemReqSparepart : '',
        }
    },
    methods:{
        
        // Todo get the section of the user
        async initView(){
            this.$store.dispatch('setOverlay', true);
            await axios.get(`${process.env.VUE_APP_URL}/api/cek_token`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            }).then(async response => {
                this.resetFilter()
                // this.resetDialogGlobal()
                this.resetFilter()
                this.resetTable()
                this.breadcumbsAssign()
                await this.getEmployee()
                await this.getUserRight()
                await this.getMill()
                this.$store.dispatch('setOverlay', false);
            }).catch(error => {
                this.$store.dispatch('setOverlay', false);
                // router.push({ path: "/" });
            })
            
        },
        breadcumbsAssign(){
            const mapping = {
                'SR': { text: 'Sunrise Steel', href: '/admin/sr' },
                'SM': { text: 'Sunrise Mill', href: '/admin/sm' },
                'KKA': { text: 'KKA', href: '/admin/kka' },
                'GBRK': { text: 'GBRK', href: '/admin/gbrk' },
            };
            const selectedMapping = mapping[this.companyId] || { text: '', href: '' };

            this.breadcumbs[0].text = selectedMapping.text;
            this.breadcumbs[0].href = selectedMapping.href;
        },
        resetFilter(){
            this.userConfig = []
            this.mill=''
            this.mills=[]
            this.isMillDisabled=false
            this.machType=''
            this.machTypes=[]
            this.isMachTypeDisabled=false
            this.machId=''
            this.machIds=[]
            this.isMachIdDisabled=false
            this.section=''
            this.sections=[]
            this.isSectionDisabled=false
            this.woStatus=''
            this.priority=''
            this.dateOf='start'
            this.startDateModal=false
            this.startDate=''
            this.endDateModal=false
            this.endDate=''
            this.role=''
            this.nik=''
            this.niks=[]
            this.dataMech = []
            this.levelFlags= {}
        },
        resetDialogGlobal(){
            this.part_machs_outs = []
        },
        resetDialogLocal(){
            this.dialogTitle=''
            this.isNew = false
            this.isUpdate = false
            this.isView = false
            this.woNum=''
            this.dialogHeaderModal=false
            this.millDialog=''
            // this.millsDialog=[]
            this.isMillDialogDisabled=false
            this.machTypeDialog=''
            this.machTypesDialog=[]
            this.isMachTypeDialogDisabled=false
            this.machIdDialog=''
            this.machIdsDialog=[]
            this.isMachIdDialogDisabled=false
            this.sectionDialog=''
            this.sectionsDialog=[]
            this.isSectionDialogDisabled=false
            this.woStatusDialog=''
            this.priorityDialog=''
            this.totalMechDialog=''
            this.startDateDialogModal=false
            this.startDateDialog=''
            this.endDateDialogModal=false
            this.endDateDialog=''
            this.remarkDialog=''
            this.isSubmit = true
            this.dataReason=[]
            this.searchItemReason=''
            this.dataCheckList=[]
            this.searchItemChecklist=''
            this.part_mach_outs = ''
            this.modal_start_date_outs = false
            this.startDateOuts = ''
            this.modal_finish_date_outs = false
            this.finishDateOuts = ''
            this.isAddReasonDisabled = true
            this.searchItemMech = ''
            this.dataManPower  = []
            this.searchItemManPower = ''
            this.dataTimeRecord = []
            this.searchItemTimeRecord = ''
            this.dialogWorkStartDate = ''
            this.dialogWorkEndDate = ''
            this.dataInventory = []
            this.searchItemInventory = ''
            this.dataReqSparepart = []
            this.searchItemReqSparepart = ''
            this.acceptedBy = ''
            this.qcBy = ''
            this.checkedBy = ''
            this.timeRecordButton = 'Add'
            this.timeRecorSeqNo = 0
        },
        resetTable(){
            this.searchItem = ''
            this.data = []
        },

        statusDisabledLogic(){
            console.log('stat', this.woStatusDialog)
            if(this.woStatusDialog == 2 || this.woStatusDialog == 3 || this.woStatusDialog == 4){
                this.isWoStatusDisabled = false
            }
        },

        async openDialog(event, dt){
            console.log(event)
            console.log(dt)
            if (event === 'new'){
                this.dialogTitle = 'Create PMT'
                this.isNew = true
                this.isUpdate = false
                this.isView = false
                this.isSubmit = true
            } else if (event === 'update'){
                this.$store.dispatch('setOverlay', true)
                console.log(dt.wo_num)
                this.woNum = dt.wo_num
                this.dialogTitle = `Edit PMT`
                this.isNew = false
                this.isUpdate = true
                this.isView = false
                this.woStatusDialog = dt.wo_status
                this.priorityDialog = dt.priority
                this.millDialog = dt.mill_id
                await this.getMachTypeDialog()
                this.machTypeDialog = dt.mach_type
                await this.getMachIdDialog()
                this.machIdDialog = dt.mach_id
                await this.getMaintSectionDialog()
                this.sectionDialog = dt.section_id
                this.totalMechDialog = dt.total_mechanic
                this.isMillDialogDisabled = true
                this.isMachTypeDialogDisabled = true
                this.isMachIdDialogDisabled = true
                this.isSectionDialogDisabled = true
                this.acceptedBy = dt.accepted_by
                this.qcBy = dt.qc_by
                this.checkedBy = dt.checked_by
                
                // handle date
                if(dt.start_date_time != '1900-01-01 00:00:00.000'){
                    this.startDateDialog = new Date((new Date(new Date(dt.start_date_time) - (new Date()).getTimezoneOffset() *
                            60000)).toISOString())
                }
                if(dt.end_date_time != '1900-01-01 00:00:00.000'){
                    this.endDateDialog = new Date((new Date(new Date(dt.end_date_time) - (new Date()).getTimezoneOffset() *
                        60000)).toISOString())
                }
                this.remarkDialog = dt.remark
                
                await this.retreivePart()
                await this.getReason()
                await this.getMechanic()
                await this.getTimeRecord()
                await this.getInventory()
                await this.getReqSparepart()
                this.$store.dispatch('setOverlay', false)
                if(this.woStatusDialog != 0){
                    if(this.woStatusDialog == 5 || this.woStatusDialog == 255){
                        this.isView = true
                    }
                    // TODO bila status lain boleh nambah reason
                    // this.isAddReasonDisabled = false
                } else {
                    this.isAddReasonDisabled = false
                }
                
                this.statusDisabledLogic()
            } else if (event === 'view'){
                this.dialogTitle = 'WO'
                this.isNew = false
                this.isUpdate = true
                this.isView = true
            } else {
                this.dialogTitle = event
                this.isNew = false
                this.isUpdate = false
                this.isView = false
            }
            this.dialogHeaderModal = true
        },

        closeDialog(event, dt){
            this.resetDialogLocal()
        },

        async getUserRight(){
            // TODO WIP
            let reqBody = {
                "cd" : this.companyId,
                "id" : '',
                "mill_id" : '',
                "section_id" : '',
                "level" : '',
                "nik" : this.$store.state.user.idemployee,
                "active_flag" : '1',
            }
            const respData = await woAprvConfig.fetcIndex(``, reqBody, false, false, false)
            if (respData.status === 200) {
                this.userConfig = respData.data.data.length > 0
                ? respData.data.data.reduce((result, item) => {
                    Object.entries(item).forEach(([key, value]) => {
                        result[key] = [...new Set((result[key] || []).concat(value.trim()))];
                    });
                    return result;
                }, {})
                : {
                    "id": [],
                    "mill_id": [],
                    "section_id": [],
                    "level": [],
                    "nik": [],
                    "active_flag": [],
                    "created_date": [],
                    "modified_date": [],
                    "created_by": [],
                    "modified_by": [],
                    "descr": [],
                    "mach_type": [],
                    "mach_id": []
                };
                this.userConfig.level.forEach((level) => {
                    const flagName = `isLevel${level}`;
                    this.$set(this.levelFlags, flagName, true);
                });
                console.log(this.levelFlags)
            }
        },

        async getMill(){
            let isSpecialCompany = ['KKA', 'GBRK'].includes(this.companyId);
            // let $url = isSpecialCompany ? `?cd=${this.companyId}&active_flag=Y&mill_type=M` : `?cd=${this.companyId}&active_flag=Y`;
            let reqBody = {
                    "cd" : this.companyId,
                    "active_flag" : 'Y',
                    "mill_id" : this.userConfig.mill_id ? this.userConfig.mill_id : '',
                    "section_id" : '',
                    "mill_name":'',
                    "mill_type" : isSpecialCompany ? 'M' : ''
                }
                console.log('ok')
            const respData = await woHeader.fetchMill('', reqBody)
            if (respData.status === 200) {
                this.mills = respData.data.data
                this.millsDialog = respData.data.data
                if(respData.data.data.length == 1){
                    this.mill = this.mills[0]['mill_id']
                    this.isMillDisabled = true
                    await this.getMachType()
                } else {
                    this.isMillDisabled = false
                }
            }
        },

        async getMachType(event){
            // let $url = `?cd=${this.companyId}&active_flag=Y&mill_id=${this.mill}`;
            let reqBody = {
                    "cd" : this.companyId,
                    "active_flag" : 'Y',
                    "mill_id" : this.mill,
                    "mach_type" : this.userConfig.mach_type ? this.userConfig.mach_type : ''
                }

            const respData = await woHeader.fetchMachType('', reqBody)
            if (respData.status === 200) {
                this.machTypes = respData.data.data
                if(respData.data.data.length == 1){
                    this.machType = this.machTypes[0]['mach_type']
                    await this.getMachId()
                    this.isMachTypeDisabled = true
                } else {
                    this.isMachTypeDisabled = false
                }
            }
        },

        async getMachTypeDialog(event){
            // let $url = `?cd=${this.companyId}&active_flag=Y&mill_id=${this.millDialog}`;
            console.log(this.userConfig)
            // console.log(this.userConfig.mach_type)
            let reqBody = {
                "cd" : this.companyId,
                "active_flag" : 'Y',
                "mill_id" : this.millDialog,
                "mach_type" : this.userConfig.mach_type ? this.userConfig.mach_type : event.mach_type
            }

            const respData = await woHeader.fetchMachType('',reqBody)
            if (respData.status === 200) {
                this.machTypesDialog = respData.data.data
                if(respData.data.data.length == 1){
                    this.machTypeDialog = this.machTypesDialog[0]['mach_type']
                    // this.isMachTypeDialogDisabled = true
                    await this.getMachIdDialog()
                } else {
                    // this.isMachTypeDialogDisabled = false
                }
            }
        },

        async getMachId(event){
            // let $url = `?cd=${this.companyId}&active_flag=Y&mill_id=${this.mill}&mach_type=${this.machType}`;
            let reqBody = {
                    "cd" : this.companyId,
                    "active_flag" : 'Y',
                    "mill_id" : this.mill,
                    "mach_type" : [this.machType],
                    "mach_id": this.userConfig.mach_id ? this.userConfig.mach_id : ['']
                }

            const respData = await woHeader.fetchMachId('', reqBody)
            if (respData.status === 200) {
                this.machIds = respData.data.data
                if(respData.data.data.length == 1){
                    this.machId = this.machIds[0]['mach_id']
                    await this.getMaintSection()
                    // this.isMachIdDisabled = true
                } else {
                    // this.isMachIdDisabled = false
                }
            }
        },

        async getMachIdDialog(event){
            // let $url = `?cd=${this.companyId}&active_flag=Y&mill_id=${this.millDialog}&mach_type=${this.machTypeDialog}`;
            let reqBody = {
                    "cd" : this.companyId,
                    "active_flag" : 'Y',
                    "mill_id" : this.millDialog,
                    "mach_type" : this.machTypeDialog,
                    "mach_id": this.userConfig.mach_id ? this.userConfig.mach_id : ''
                }

            const respData = await woHeader.fetchMachId('', reqBody)
            if (respData.status === 200) {
                this.machIdsDialog = respData.data.data
                if(respData.data.data.length == 1){
                    this.machIdDialog = this.machIdsDialog[0]['mach_id']
                    await this.getMaintSectionDialog()
                    this.isMachIdDisabled = true
                } else {
                    this.isMachIdDisabled = false
                }
            }
        },

        async getMaintSection(event){
            let reqBody = {
                cd: this.companyId,
                mill_id: this.mill,
                // section_id: this.userConfig.section_id ? this.userConfig.section_id : '',
                section_id: typeof this.userConfig.section_id === 'string' ? [this.userConfig.section_id] : this.userConfig.section_id || '',
                descr: '',
                active_flag: "Y",
                mach_type:[this.machType],
                mach_id:[this.machId]
            }

            const respData = await woHeader.fetchMaintSection('', reqBody)
            if (respData.status === 200) {
                this.sections = respData.data.data
                if(respData.data.data.length == 1){
                    this.section = this.sections[0]['section_id']
                    this.isSectionDisabled = true
                } else {
                    this.isSectionDisabled = false
                }
            }
        },

        async getMaintSectionDialog(event){
            let reqBody = {
                cd: this.companyId,
                mill_id: this.millDialog,
                // section_id: this.userConfig.section_id ? this.userConfig.section_id : '',
                section_id: typeof this.userConfig.section_id === 'string' ? [this.userConfig.section_id] : this.userConfig.section_id || '',
                descr: '',
                active_flag: "Y",
                mach_type:[this.machTypeDialog],
                mach_id:[this.machIdDialog]
            }

            const respData = await woHeader.fetchMaintSection('', reqBody)
            if (respData.status === 200) {
                this.sectionsDialog = respData.data.data
                if(respData.data.data.length == 1){
                    this.sectionDialog = this.sectionsDialog[0]['section_id']
                    this.isSectionDisabled = true
                } else {
                    this.isSectionDisabled = false
                }
            }
        },

        async getOutstandingCheckList(event){
            var periodStart = this.startDateOuts ? new Date(this.startDateOuts).toISOString().slice(0, 10) : ''
            var periodEnd = this.finishDateOuts ? new Date(this.finishDateOuts).toISOString().slice(0, 10) : ''
            let reqBody = {
                cd: this.companyId,
                mill_id: this.millDialog,
                section_id: this.sectionDialog,
                descr: '',
                active_flag: "Y",
                mach_type:this.machTypeDialog,
                mach_id:this.machIdDialog,
                tr_id : "",
                part_id : this.part_mach_outs,
                period :"",
                shift_id : "",
                maintained_by:"",
                trans_date:periodStart,
                end_date:periodEnd,
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await woHeader.checkListOutstanding('', reqBody)
            if (respData.status === 200) {
                this.dataCheckList = respData.data.data
            }
            this.$store.dispatch('setOverlay', false)
        },

        async getEmployee(){
            console.log('get employee')
            const companyConfig = {
                'TEST': { company: 'SRS', dept: 'MNT', div: '', sec: '' },
                'SR': { company: 'SRS', dept: 'MNT', div: '', sec: '' },
                'SM': { company: 'SRM', dept: 'MNT', div: '', sec: '' },
                'KKA': { company: 'KKA', dept: 'MNT', div: '', sec: '' },
                'GBRK': { company: 'GBRK', dept: 'MNT', div: '', sec: '' },
            };
            let val = companyConfig[this.companyId]
            let reqBody = {
                cd : this.companyId,
                company_id : [val.company],
                idemployee : [],
                office_id : [],
                dept_id : [val.dept],
                division_id : [],
                section_id : [],
                group_id : [],
                active_flag : ['Y'],
            }
            const respData = await woHeader.fetchEmployee('', reqBody)
            if (respData.status === 200) {
                this.niks = respData.data.data
                this.dataMech = respData.data.data
            }
            
        },

        async getInventory(event){
            let reqBody = {
                cd: this.companyId,
                article_id: [],
                cat_id: ["05"],
                com_id: [],
                active_flag: ["Y"]
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await woHeader.currentSoh('', reqBody)
            if (respData.status === 200) {
                this.dataInventory = respData.data.data
            }
            this.$store.dispatch('setOverlay', false)
        },

        async getHeader(event, dt){
            let reqBody = {};
            let start_date = this.startDate ? new Date(this.startDate).toISOString().slice(0, 10).replace(/-/g,"") : ''
            let end_date = this.endDate ? new Date(this.endDate).toISOString().slice(0, 10).replace(/-/g,"") : ''
            if (event === 'search'){
                reqBody = {
                    "cd": this.companyId,
                    "mill_id": this.mill,
                    "wo_num": "",
                    "wo_status": this.woStatus,
                    "mach_type": this.machType,
                    "mach_id": this.machId,
                    "section_id": this.section,
                    "priority": this.priority,
                    "which_date": this.dateOf,
                    "start_date": start_date,
                    "end_date": end_date,
                    "which_role": this.role,
                    "nik": this.nik,
                    "wo_type": "SC",
                }
            } else {
                reqBody = {
                    "cd": this.companyId,
                    "mill_id": "",
                    "wo_num": dt.wo_num,
                    "wo_status": "",
                    "mach_type": "",
                    "mach_id": "",
                    "section_id": "",
                    "priority": "",
                    "which_date": "",
                    "start_date": "",
                    "end_date": "",
                    "which_role": "",
                    "nik": "",
                    "wo_type": "SC",
                }
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await woHeader.fetchIndex(``, reqBody, false, false, false)
            if (respData.status === 200) {
                if (event === 'search'){
                    this.data = respData.data.data
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    // TODO buka dialog
                    // assign var
                    
                    this.editWo(event, respData.data.data[0])
                    
                }
                
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },

        async editWo(event, dt){
            this.openDialog(event, dt)
        },

        async save(event, stat, dt){
            if(![this.priorityDialog, this.millDialog, this.machTypeDialog, this.machIdDialog, this.sectionDialog].every(Boolean)){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                }
            } else if(stat == 5 && ![this.totalMechDialog, this.startDateDialog, this.endDateDialog, this.acceptedBy, this.qcBy, this.checkedBy].every(Boolean)) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                }
            } else {
                if(stat == 5 && this.endDateDialog == ''){
                    this.endDateDialog = new Date().toISOString().slice(0, 16)
                }
                let arb = stat
                if(stat == 3){
                    arb = this.woStatusDialog
                }
                const reqBody = {
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    'wo_type': 'SC',
                    'wo_status': arb,
                    'mach_type': this.machTypeDialog,
                    'mach_id': this.machIdDialog,
                    "section_id": this.sectionDialog,
                    'priority': this.priorityDialog,
                    'total_mechanic': this.totalMechDialog === "" ? '0' : this.totalMechDialog,
                    'start_date_time': this.startDateDialog ? new Date(this.startDateDialog).toISOString().slice(0, 16) : '',
                    'end_date_time': this.endDateDialog ? new Date(this.endDateDialog).toISOString().slice(0, 16) : '',
                    "remark": this.remarkDialog,
                    "submit_by": event === 'submit' ? this.$store.state.user.idemployee : '',
                    "submit_date": event === 'submit' ? new Date().toISOString().slice(0, 16) : '',
                    "approved_by": event === 'approve' ? this.$store.state.user.idemployee : '',
                    "approved_date": event === 'approve' ? new Date().toISOString().slice(0, 16) : '',
                    // "accepted_by": event === 'accept' ? this.$store.state.user.idemployee : '',
                    "accepted_by": this.acceptedBy,
                    "accepted_date": event === 'accept' ? new Date().toISOString().slice(0, 16) : '',
                    // "qc_by": event === 'qc' ? this.$store.state.user.idemployee : '',
                    "qc_by": this.qcBy,
                    "qc_date": event === 'qc' ? new Date().toISOString().slice(0, 16) : '',
                    // "checked_by": event === 'check' ? this.$store.state.user.idemployee : '',
                    "checked_by": this.checkedBy,
                    "checked_date": event === 'check' ? new Date().toISOString().slice(0, 16) : '',
                    "created_by": this.$store.state.user.idemployee,
                    "modified_by": this.$store.state.user.idemployee
                };
                this.$store.dispatch('setOverlay', true)
                let isValidated = true
                if (event === 'submit'){
                    // TODO cek reason dulu
                    await this.getReason()
                    isValidated = this.dataReason.length === 0 ? false : true
                }
                if (isValidated === true){
                    let respData
                    if (this.isUpdate){
                        reqBody.wo_num = this.woNum
                        respData = await woHeader.update('', reqBody, false, false, false)
                    } else {
                        respData = await woHeader.store('', reqBody, false, false, false)
                    }
                    if (respData.status != 200) {
                        this.$store.dispatch('setOverlay', false)
                        this.snackbar = {
                            color: "warning",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Warning",
                            text: respData.data.data,
                            visible: true
                        };
                        return false
                    } else {
                        this.$store.dispatch('setOverlay', false)
                        this.isNew = false
                        this.isUpdate = true
                        this.isView = false
                        this.isAddReasonDisabled = false
                        this.woNum = respData.data.data
                        // await this.retreivePart()
                        // await this.getHeader('search',[])
                        // get partlist
                        this.woStatusDialog = stat.toString()
                        if (this.isUpdate){
                            // this.closeDialog()
                        }
                        if(this.woStatusDialog == '1' || this.woStatusDialog == '2' || stat == '255'){
                            this.closeDialog()
                        } else {
                            await this.retreivePart()
                        }
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: (this.isUpdate)? `WO had been updated` : `WO had been created`,
                            visible: true
                        };
                    }
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: `Please add some reason.`,
                        visible: true
                    };
                }
            }
        },

        async update(event, dt){
            if(![this.priorityDialog, this.millDialog, this.machTypeDialog, this.machIdDialog, this.sectionDialog].every(Boolean)){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                }
            } else {
                const reqBody = {
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    'wo_num' : this.woNum,
                    'wo_status': this.woStatusDialog,
                    'mach_type': this.machTypeDialog,
                    'mach_id': this.machIdDialog,
                    "section_id": this.sectionDialog,
                    'priority': this.priorityDialog,
                    'total_mechanic': this.totalMechDialog === "" ? '0' : this.totalMechDialog,
                    'start_date_time': this.startDateDialog ? new Date(this.startDateDialog).toISOString().slice(0, 16) : '',
                    'end_date_time': this.endDateDialog ? new Date(this.endDateDialog).toISOString().slice(0, 16) : '',
                    "remark": this.remarkDialog,
                    "submit_by": event === 'submit' ? this.$store.state.user.idemployee : '',
                    "submit_date": event === 'submit' ? new Date().toISOString().slice(0, 16) : '',
                    "approved_by": '',
                    "approved_date": '',
                    "accepted_by": this.acceptedBy,
                    "accepted_date": '',
                    "qc_by": this.qcBy,
                    "qc_date": '',
                    "checked_by": this.checkedBy,
                    "checked_date": '',
                    "created_by": this.$store.state.user.idemployee,
                    "modified_by": this.$store.state.user.idemployee
                };
                this.$store.dispatch('setOverlay', true)
                const isValidated = true
                if (event === 'submit'){
                    // TODO cek reason dulu
                    await this.getReason()
                    isValidated = this.dataReason.length === 0 ? false : true
                }
                if (isValidated === true){
                    const respData = await woHeader.update('', reqBody, false, false, false)
                    if (respData.status != 200) {
                        this.$store.dispatch('setOverlay', false)
                        this.snackbar = {
                            color: "warning",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Warning",
                            text: respData.data.data,
                            visible: true
                        };
                        return false
                    } else {
                        this.$store.dispatch('setOverlay', false)
                        // this.isNew = false
                        // this.isUpdate = true
                        // this.isView = false
                        // this.isAddReasonDisabled = false
                        // this.woNum = respData.data.data
                        // await this.retreivePart()
                        // await this.getHeader('search',[])
                        // get partlist
                        // this.closeDialog()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: `WO had been update`,
                            visible: true
                        };
                    }
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: `Please add some reason.`,
                        visible: true
                    };
                }
            }
        },

        async retreivePart(){
            const r={
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'mach_type': this.machTypeDialog,
                'mach_id': this.machIdDialog,
                'section_id': this.sectionDialog,
                'part_id': '',
                'active_flag': 'Y',
            }
            const rData = await woHeader.fetchPartList('',r)
            if (rData.status === 200) {
                this.part_machs_outs = rData.data.data
            }
        },

        async getReason(){
            this.$store.dispatch('setOverlay', true)
            const body = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'seq_no': "",
                'tr_id': "",
                'check_id': ""
            }
            const rData = await woHeader.fetchCause('', body)
            if (rData.status === 200){
                this.dataReason = rData.data.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async addReason(event, dt) {
            // console.log(event)
            // console.log(dt)
            const reqBody = {
                'cd': this.companyId,
                'mill_id': dt.mill_id,
                'wo_num': this.woNum,
                'wo_type': 'SC',
                'tr_id': dt.tr_id,
                'check_id': dt.check_id,
                'created_by': this.$store.state.user.idemployee,
                'modified_by': this.$store.state.user.idemployee,
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await woHeader.storeCause('', reqBody)
            if (respData.status === 200){
                const body = {
                    'cd': this.companyId,
                    'mill_id': dt.mill_id,
                    'wo_num': this.woNum,
                    'seq_no': "",
                    'tr_id': "",
                    'check_id': ""
                }
                const rData = await woHeader.fetchCause('', body)
                if (rData.status === 200){
                    this.dataReason = rData.data.data
                    // console.log(this.dataCheckList)
                    // this.dataCheckList.filter(item => !(item.tr_id === dt.tr_id && item.check_id === dt.check_id));
                    this.dataCheckList = this.dataCheckList.filter(item => {
                        const shouldRemove = item.tr_id === dt.tr_id && item.check_id === dt.check_id;
                        // console.log(`Checking item with tr_id=${item.tr_id} and check_id=${item.check_id}: Should remove? ${shouldRemove}`);
                        return !shouldRemove;
                    });
                    // console.log(this.dataCheckList)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async deleteReason(event, dt){
            // console.log(event)
            // console.log(dt)
            this.$store.dispatch('setOverlay', true)
            const reqBody = {
                'cd': this.companyId,
                'mill_id': dt.mill_id,
                'wo_num': dt.wo_num,
                "seq_no": dt.seq_no,
                "tr_id": dt.tr_id,
                "check_id": dt.check_id
            }
            const rData = await woHeader.deleteCause('', reqBody)
            if (rData.status === 200){
                await this.getReason()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async getMechanic(){
            this.$store.dispatch('setOverlay', true)
            const body = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'seq_no': "",
                'tr_id': "",
                'check_id': ""
            }
            const rData = await woHeader.fetchManPower('', body)
            if (rData.status === 200){
                this.dataManPower = rData.data.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async addMechanic(event, dt) {
            const reqBody = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'mechanic': dt.idemployee,
                'created_by': this.$store.state.user.idemployee,
                'modified_by': this.$store.state.user.idemployee,
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await woHeader.storeManPower('', reqBody)
            if (respData.status === 200){
                const body = {
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    'wo_num': this.woNum,
                    'seq_no': "",
                    'tr_id': "",
                    'check_id': ""
                }
                const rData = await woHeader.fetchManPower('', body)
                if (rData.status === 200){
                    this.dataManPower = rData.data.data
                    // console.log(this.dataCheckList)
                    // this.dataCheckList.filter(item => !(item.tr_id === dt.tr_id && item.check_id === dt.check_id));
                    this.dataMech = this.dataMech.filter(item => {
                        const shouldRemove = item.idemployee === dt.idemployee;
                        // console.log(`Checking item with tr_id=${item.tr_id} and check_id=${item.check_id}: Should remove? ${shouldRemove}`);
                        return !shouldRemove;
                    });
                    // console.log(this.dataCheckList)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async deleteMechanic(event, dt) {
            this.$store.dispatch('setOverlay', true)
            const reqBody = {
                'cd': this.companyId,
                'mill_id': dt.mill_id,
                'wo_num': this.woNum,
                "seq_no": dt.seq_no
            }
            const rData = await woHeader.deleteManPower('', reqBody)
            if (rData.status === 200){
                await this.getMechanic()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        timeRecordTableClick(item) {
            this.timeRecorSeqNo = item.seq_no
            this.dialogWorkStartDate =  new Date((new Date(new Date(item.start_date_time) - (new Date()).getTimezoneOffset() * 60000)).toISOString())
            this.dialogWorkEndDate = new Date((new Date(new Date(item.end_date_time) - (new Date()).getTimezoneOffset() * 60000)).toISOString())
            this.timeRecordButton = "Update"
        },

        clearTimeRecord(){
            this.timeRecorSeqNo = ''
            this.dialogWorkStartDate =  ''
            this.dialogWorkEndDate = ''
            this.timeRecordButton = "Add"
        },

        getTimeRecordRequestCommonBody() {
            return {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'seq_no': "",
                'code': "",
                'start_date_time': "",
                'end_date_time': ""
            };
        },

        async getTimeRecord(event, dt) {
            this.$store.dispatch('setOverlay', true)
            const body = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'seq_no': "",
                'code': "",
                'start_date_time': "",
                'end_date_time': ""
            }
            const rData = await woHeader.fetchTimeRecord('', body)
            if (rData.status === 200){
                this.dataTimeRecord = rData.data.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async addTimeRecord(event, dt) {
            this.timeRecordButtonDisabled = true
            const reqBody = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'code_id': '1',
                'start_date_time': this.dialogWorkStartDate ? new Date(this.dialogWorkStartDate).toISOString().slice(0, 16) : '',
                'end_date_time': this.dialogWorkEndDate ? new Date(this.dialogWorkEndDate).toISOString().slice(0, 16) : '',
                'created_by': this.$store.state.user.idemployee,
                'modified_by': this.$store.state.user.idemployee,
            }
            if (this.timeRecordButton == "Update") {
                reqBody['seq_no'] = this.timeRecorSeqNo;
            }
            this.$store.dispatch('setOverlay', true)
            var respData;
            if (this.timeRecordButton == "Update") {
                respData = await woHeader.updateTimeRecord('', reqBody)
            } else {
                respData = await woHeader.storeTimeRecord('', reqBody)
            }
            if (respData.status === 200){
                const body = {
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    'wo_num': this.woNum,
                    'seq_no': "",
                    'code': "",
                    'start_date_time': "",
                    'end_date_time': ""
                }
                const rData = await woHeader.fetchTimeRecord('', body)
                if (rData.status === 200){
                    this.dataTimeRecord = rData.data.data
                    this.dialogWorkStartDate = ''
                    this.dialogWorkEndDate = ''
                    this.timeRecorSeqNo = 0
                    this.timeRecordButton = 'Add'
                    this.timeRecordButtonDisabled = false
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } else {
                this.$store.dispatch('setOverlay', false)
            }
            this.timeRecordButtonDisabled = false
        },

        async delTimeRecord(event, dt) {
            this.$store.dispatch('setOverlay', true)
            this.timeRecorSeqNo = ''
            this.dialogWorkStartDate =  ''
            this.dialogWorkEndDate = ''
            this.timeRecordButton = "Add"
            const reqBody = {
                'cd': this.companyId,
                'mill_id': dt.mill_id,
                'wo_num': this.woNum,
                "seq_no": dt.seq_no
            }
            const rData = await woHeader.deleteTimeRecord('', reqBody)
            if (rData.status === 200){
                await this.getTimeRecord()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async getReqSparepart(){
            this.$store.dispatch('setOverlay', true)
            const body = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'commodity_id': "",
                'seq_no': "",
                'prod_code': ""
            }
            const rData = await woHeader.fetchSparePartReq('', body)
            if (rData.status === 200){
                this.dataReqSparepart = rData.data.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async addReqSparepart(event, dt) {
            const reqBody = {
                'cd': this.companyId,
                'mill_id': this.millDialog,
                'wo_num': this.woNum,
                'prod_code': dt.article_id,
                'req_qty': dt.req_qty,
                'created_by': this.$store.state.user.idemployee,
                'modified_by': this.$store.state.user.idemployee,
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await woHeader.storeSparePartReq('', reqBody)
            if (respData.status === 200){
                const body = {
                    'cd': this.companyId,
                    'mill_id': this.millDialog,
                    'wo_num': this.woNum,
                    'commodity_id': "",
                    'seq_no': "",
                    'prod_code': ""
                }
                const rData = await woHeader.fetchSparePartReq('', body)
                if (rData.status === 200){
                    this.dataReqSparepart = rData.data.data
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                }
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },

        async deleteReqSparepart(event, dt) {
            this.$store.dispatch('setOverlay', true)
            const reqBody = {
                'cd': this.companyId,
                'mill_id': dt.mill_id,
                'wo_num': this.woNum,
                "seq_no": dt.seq_no
            }
            const rData = await woHeader.deleteSparePartReq('', reqBody)
            if (rData.status === 200){
                await this.getReqSparepart()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.initView()
        });
    },
    beforeRouteLeave(to, from, next) {
        // this.resetDialogGlobal()
        this.resetFilter()
        this.resetTable()
        next()
    },
}
</script>